import { ServicesPreferencesModalProps } from '@wix/bookings-services-preferences-modal/types';

export enum DialogType {
  Premium = 'premium',
  ServicePreferences = 'servicePreferences',
}
export interface PremiumDialogViewModel {
  type: DialogType;
  isOpen: boolean;
  data?: never;
}

export interface ServicePreferencesDialogViewModel {
  type: DialogType.ServicePreferences;
  data: Omit<
    ServicesPreferencesModalProps,
    'onClose' | 'onConfirm' | 'onServiceSelected' | 'formatterKeysMap' | 't'
  >;
  isOpen: boolean;
}

export type DialogViewModel =
  | PremiumDialogViewModel
  | ServicePreferencesDialogViewModel;

export const createDialogViewModel = ({
  type = DialogType.Premium,
  isOpen = false,
  data = undefined,
}: Partial<DialogViewModel> = {}): DialogViewModel => {
  return {
    data: data as any,
    type: type as DialogViewModel['type'],
    isOpen,
  };
};
